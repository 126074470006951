<template>
    <v-container fluid fill-height class="primary">
        <v-row align="center">
            <v-col lg="4" sm="6" xs="12" class="mx-auto">
                <v-card rounded :loading="loading">
                    <v-card-text>
                        <v-row justify="end">
                            <language-select></language-select>
                        </v-row>
                    </v-card-text>
                    <v-card-title
                        class="text-h5 font-weight-black justify-center"
                    >
                        {{ title }}
                    </v-card-title>
                    <v-card-text class="pb-10">
                        <slot />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import LanguageSelect from "@/components/LanguageSelect.vue";
export default {
    name: "LoginAndRegistLayout",
    components: { LanguageSelect },
    props: ["title", "loading"],
};
</script>